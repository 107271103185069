import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { ArticleTeaser } from 'src/components/organisms/ArticleTeaser';
import { FluidImg } from 'src/embeds/image/image-dato';

export default function Drawer({ preHeading, heading, content, image, contentNode, ctas, customData }) {
  customData = JSON.parse(customData);
  const [cta] = ctas;

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        <ArticleTeaser
          image={!!image && <FluidImg data={image} maxWidth={null} style={{ marginTop: '-2rem' }} />}
          verticalAlign="center"
        >
          {preHeading && <h5 className="highlight">{preHeading}</h5>}
          {heading && <h3>{heading}</h3>}
          {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
          {cta && <Button to={cta.url}>{cta.text}</Button>}
        </ArticleTeaser>
      </Section>
    </MDXProvider>
  );
}
